import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot, faUser } from "@fortawesome/free-solid-svg-icons";
import "./ChatDisplay.css"; // Assuming you have some CSS for styling

const ChatDisplay = ({
  clientData,
}) => {

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    const isToday = date.toDateString() === today.toDateString();

    
      return date.toLocaleTimeString('fr-FR', {
        day: '2-digit',
        month: 'long',
        hour: '2-digit',
        minute: '2-digit',
      });

  };

  // Function to decode MIME encoded words
  const decodeMimeWord = (encodedWord) => {

    if (!encodedWord) return encodedWord;

    const match = encodedWord.match(/=\?([^?]+)\?(B|Q)\?([^?]+)\?=/i);
    if (!match) return encodedWord;

    const charset = match[1].toLowerCase();
    const encoding = match[2].toUpperCase();
    const text = match[3];

    try {
        if (encoding === "B") {
            // Base64 decoding
            return decodeURIComponent(
                escape(atob(text))
            );
        } else if (encoding === "Q") {
            // Quoted-Printable decoding
            return decodeURIComponent(
                escape(
                    text.replace(/_/g, " ").replace(/=([A-Fa-f0-9]{2})/g, (match, p1) => 
                        String.fromCharCode(parseInt(p1, 16))
                    )
                )
            );
        }
    } catch (e) {
        console.error("Decoding error", e);
        return encodedWord; // Return the original string if decoding fails
    }
    return encodedWord;
};

  return (
    <div className="chatbot">
      <div className="chat-header">
        <h2 className="client-pseudo">{decodeMimeWord(clientData.client_pseudo)}</h2>
        {clientData.conversation_type === "le boncoin" && (
          <>
            <h3 className="ad-title">Annonce : {clientData.product}</h3>
            <p className="ad-link">
              Lien vers Leboncoin :&nbsp;
              <a href={clientData.connector_conv_link} target="_blank" rel="noopener noreferrer">
                {clientData.connector_conv_link}
              </a>
            </p>
          </>
        )}
        <h4 className="client-email">Email : {clientData.client_email}</h4>
        <h4 className="client-id">Conversation ID: {clientData.client_id}</h4>
      </div>
      <div className="chatbot-messages">
        {clientData.conversation && clientData.conversation.messages && clientData.conversation.messages.map((msg, index) => (
          <div key={index} className={`message-row ${msg.type}`}>
            <div className="icon-container">
              <FontAwesomeIcon icon={msg.type === "client" ? faUser : faRobot} />
            </div>
            <div className={`message ${msg.type}`}>
              <span className="message-content">
                {msg.content}
              </span>
              <span className="message-time">
                {formatDate(msg.time_received)}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatDisplay;
