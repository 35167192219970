import React, { useState, useEffect } from "react";

const ProductItem = ({ product, onDelete, onQuantityChange }) => {
  const [quantity, setQuantity] = useState(product.product.nb_pcs?.nb_pcs || 0);

  useEffect(() => {
    setQuantity(product.product.nb_pcs?.nb_pcs || 0);
  }, [product.product.nb_pcs?.nb_pcs]);

  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity >= 0) {
      setQuantity(newQuantity);
      onQuantityChange(newQuantity);
    }
  };
  
  return (
    <li>
      <div className="item-header">
        <strong>Nom du produit :</strong> {product.product.name}
        <strong>Nombre de paquets :</strong> {product.product.nb_pcs?.nb_pack || "aucun"}
      </div>
      <div className="item-actions">
        <button onClick={onDelete}>Supprimer</button>
        <label>
          Quantité:
          <input
            type="number"
            value={quantity}
            onChange={handleQuantityChange}
            min="0"
            style={{ width: '60px', marginLeft: '5px' }}
          />
        </label>
      </div>
    </li>
  );
};

export default ProductItem;