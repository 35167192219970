import React, { useState } from "react";
import "./Login.css"; // Create this file for styling the login page

const Login = ({ onLogin }) => {
  const [userId, setUserId] = useState(""); // New state for user_id
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = () => {
    const correctPassword = "3USts6GS2rPf8PcJpOTO"; // Replace with your password
    const validUserIds = ["user_1", "user_2", "user_3", "user_4", "user_5", "user_6", "user_7", "user_8", "user_9", "user_10", "user_11", "user_12", "user_13", "user_14", "user_15", "user_16", "user_17", "user_18", "user_19", "user_20"];

    if (validUserIds.includes(userId) && password === correctPassword) {
      const expirationTime = new Date().getTime() + 30 * 60 * 1000; // 30 minutes expiration
      localStorage.setItem("authToken", JSON.stringify({ expirationTime }));
      localStorage.setItem("userId", userId); // Store user_id in localStorage
      onLogin(); // Trigger login success
    } else {
      setError("ID utilisateur ou mot de passe incorrect");
    }
  };

  return (
    <div className="login-container">
      <h2>Connexion</h2>
      <input
        type="text"
        value={userId}
        onChange={(e) => setUserId(e.target.value)}
        placeholder="ID utilisateur"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Mot de passe"
      />
      <button onClick={handleLogin}>Se connecter</button>
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default Login;
