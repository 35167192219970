import React, { useState, useRef, useEffect } from "react";
import "./TestIA.css";

const userId = localStorage.getItem('userId');

const TestIA = () => {
  const [message, setMessage] = useState("");
  const [ad, setAd] = useState(""); // Nouvel état pour l'annonce
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);
  const websocket = useRef(null);

  useEffect(() => {
    const ws_host = process.env.REACT_APP_WEBSOCKET_HOST || `${window.location.host}`;

    websocket.current = new WebSocket(
      `ws://${ws_host}/ws/chat_bot/chat/${userId}`
    );

    websocket.current.onopen = () => {
      console.log("WebSocket connection established");
    };

    websocket.current.onmessage = (event) => {
      const responseMessage = { content: event.data, type: "server" };
      setMessages((prevMessages) => [...prevMessages, responseMessage]);
    };

    websocket.current.onclose = () => {
      console.log("WebSocket connection closed");
    };

    return () => {
      websocket.current.close();
    };
  }, []);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const sendMessage = () => {
    if (message.trim()) {
      const newMessage = { content: message, type: "client" };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      const messageData = JSON.stringify({ message, ad }); // Envoi du message et de l'annonce
      websocket.current.send(messageData);
      setMessage("");
    }
  };

  return (
    <div className="test-ia">
      <div className="chat-header">
        <h2>Test IA</h2>
      </div>
      <div className="chat-ad">
        <input
          type="text"
          value={ad}
          onChange={(e) => setAd(e.target.value)}
          placeholder="Setup an announcement..."
        />
      </div>
      <div className="chat-messages">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.type}`}>
            <span>{msg.content}</span>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type a message..."
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              sendMessage();
            }
          }}
        />
        <button onClick={sendMessage}>Send</button>
      </div>
    </div>
  );
};

export default TestIA;
