import React from "react";

const DevisButtons = ({ handleShowDevis, handleDownloadDevis, handleSendDevis, handleEditDevis }) => {
  return (
    <div className="devis-buttons">
      <button onClick={handleShowDevis}>Afficher Devis</button>
      <button onClick={handleDownloadDevis}>Télécharger Devis</button>
      <button onClick={handleSendDevis}>Envoyer Devis</button>
      {/* <button onClick={handleEditDevis} style={{ backgroundColor: 'yellow', color: 'black' }}>Modifier le Devis</button> */}
    </div>
  );
};

export default DevisButtons;
