import React, { useState } from "react";
import "./Admin.css";

const userId = localStorage.getItem("userId");

const AdminPage = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  const handleClick = (id) => {
    setSelectedId(id);
    setShowPopup(true);
  };

  const handleConfirm = async () => {
    try {
      const http_host =
      process.env.REACT_APP_HTTP_HOST || `${window.location.host}`;

    const response = await fetch(`http://${http_host}/api/stores/init_stores`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: selectedId.toString() }),
      });

      if (response.ok) {
        setSuccessMessage(`Store initialized for user ${selectedId}`);
      } else {
        setSuccessMessage("Failed to initialize store.");
      }
    } catch (error) {
      setSuccessMessage("Error occurred.");
    } finally {
      setShowPopup(false);
      setSelectedId(null);
    }
  };

  const handleCancel = () => {
    setShowPopup(false);
    setSelectedId(null);
  };

  return (
    <div>
      <h1>Admin Page</h1>
      <div className="button-container">
        {Array.from({ length: 20 }, (_, index) => (
          <button key={index + 1} onClick={() => handleClick(index + 1)}>
            Init User {index + 1}
          </button>
        ))}
      </div>

      {showPopup && (
        <div>
          <div className="popup-overlay" onClick={handleCancel}></div>
          <div className="popup">
            <p>Are you sure you want to initialize store for user {selectedId}?</p>
            <button onClick={handleConfirm}>Yes</button>
            <button onClick={handleCancel}>No</button>
          </div>
        </div>
      )}

      {successMessage && (
        <div className="popup">
          <p>{successMessage}</p>
          <button onClick={() => setSuccessMessage("")}>OK</button>
        </div>
      )}
    </div>
  );
};

export default AdminPage;
