// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./Dashboard";
import TestIA from "./TestIA";
import Admin from "./Admin";
import "./App.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          
          <Route path="/test" element={<TestIA />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/admin" element={<Admin />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
