import React, { useState, useEffect } from 'react';
import './TopBarStore.css';
import StoreConfigModal from './StoreConfigModal';
import { useNavigate } from 'react-router-dom';

function TopBarStore() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const navigate = useNavigate();
  
  const userId = localStorage.getItem('userId'); // Get the userId from localStorage

  useEffect(() => {
    const fetchStores = async () => {
      const http_host = process.env.REACT_APP_HTTP_HOST || `${window.location.host}`;
      const response = await fetch(`http://${http_host}/api/stores/get_stores`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user_id: userId })
      });
      const data = await response.json();
      console.log('API Response:', data); // Log API response
      setStores(data);
    };

    fetchStores();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const selectStore = async (store) => {
    setSelectedStore(store);
    setDropdownOpen(false);

    const http_host = process.env.REACT_APP_HTTP_HOST || `${window.location.host}`;
    await fetch(`http://${http_host}/api/stores/set_store`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: store.id, user_id: userId })
    });

    // Reload the page to reflect the changes
    window.location.reload();
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userId');
    window.location.href = "/"
  };

  return (
    <div className="top-bar-store">
      <div className="left-buttons">
        <button className="change-depot-button" onClick={toggleDropdown}>
          Changer dépôt
        </button>
        {dropdownOpen && (
          <div className="dropdown">
            {stores.length === 0 && <div className="dropdown-item">No stores available</div>}
            {stores.map((store) => (
              <div key={store.id} className="dropdown-item" onClick={() => selectStore(store)}>
                {store.name}
              </div>
            ))}
          </div>
        )}
      </div>
      
      <div className="middle-button">
        {userId && (
          <div className="user-info">
            <span>Connecté avec : {userId}</span>
            <button className="logout-button" onClick={handleLogout}>Déconnexion</button>
          </div>
        )}
      </div>

      <div className="right-buttons">
        <button className="configure-depot-button" onClick={openModal}>Configurer les dépôts</button>
      </div>

      
      {isModalOpen && <StoreConfigModal closeModal={closeModal} />}
    </div>
  );
}

export default TopBarStore;
