import React from "react";

const StockItem = ({ item }) => {
  return (
    <li className="stock-item">
      <div className="item-header">
        <p><strong>Categorie :</strong> {item.category}</p>
        <p><strong>Label :</strong> {item.label}</p>
      </div>
      <p><strong>Dimensions :</strong> longueur: {item.item_dimensions.length}mm, largeur: {item.item_dimensions.width} mm, épaisseur: {item.item_dimensions.thick} mm, Surface: {item.item_dimensions.surface} m²</p>
      <p><strong>Prix :</strong> Par pièces : {item.item_prices.price_pcs}€, Par m²: {item.item_prices.price_m2}€, Par package: {item.item_prices.price_pack}€</p>
      <p><strong>Stock :</strong> Nbrs stock: {item.item_stock_quantity.nb_pcs} pcs, Surface: {item.item_stock_quantity.surface_stock} m², Length: {item.item_stock_quantity.length_stock} m</p>
    </li>
  );
};

export default StockItem;
