import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import ReportDisplay from "./ReportDisplay";
import Sidebar from "./Sidebar";
import ChatDisplay from "./ChatDisplay";
import TopBar from "./TopBar";
import useWebSockets from "./useWebSockets";
import Login from "./Login";
import TopBarStore from "./TopBarStore";

const initialReport = {
  report_projects: { projects: [] },
  report_products: { report_products: [] }
};

function Dashboard() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState(null); // New state for userId

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const storedUserId = localStorage.getItem("userId");

    if (authToken && storedUserId) {
      const { expirationTime } = JSON.parse(authToken);
      if (new Date().getTime() < expirationTime) {
        setIsAuthenticated(true);
        setUserId(storedUserId); // Set the userId state from localStorage
      } else {
        localStorage.removeItem("authToken");
        localStorage.removeItem("userId");
      }
    }
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
    setUserId(localStorage.getItem("userId")); // Set userId after login
  };

  const [report, setReport] = useState(initialReport);
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState("");
  const [clientData, setClientData] = useState({
    clientId: "",
    clientName: "",
    clientPseudo: "",
    clientEmail: "",
    clientAdLink: "",
    ad: "",
    products: [],
    report: [],
    initialProposedAnswer: ""
  });

  const { updateClientDataWebSocket, addProduct, changeProductQuantity, deleteProduct } = useWebSockets({
    setConversations,
    setSelectedConversation,
    setClientData
  });

  const selectConversation = (conv) => {
    setSelectedConversation(conv);
    updateClientDataWebSocket(conv.client_id);
  };

  return (
    <div className="App">
      {isAuthenticated ? (
        <div className="chatbot-container">
          <TopBarStore />
          <TopBar userId={userId} /> 
          <div className="main-content">
            <div className="sidebar-container">
              <Sidebar conversations={conversations} selectConversation={selectConversation} userId={userId} />
            </div>
            <div className="report-container">
              <ReportDisplay
                clientData={clientData}
                clientId={selectedConversation.client_id}
                initialProposedAnswer={clientData.initialProposedAnswer}
                setProducts={(products) => setClientData((prevData) => ({ ...prevData, products }))}
                addProduct={addProduct}
                changeProductQuantity={changeProductQuantity}
                deleteProduct={deleteProduct}
                userId={userId} 
              />
            </div>
            <div className="chat-container">
              <ChatDisplay
                clientData={clientData}
                userId={userId}
              />
            </div>
          </div>
        </div>
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </div>
  );
}

export default Dashboard;
