const userId = localStorage.getItem('userId');

export const connectClientDataWebSocket = (
  clientId,
  onMessageCallback,
  onErrorCallback
) => {

  const websocketHost = process.env.REACT_APP_WEBSOCKET_HOST || `${window.location.host}`;

  const clientDataWebsocket = new WebSocket(
    `ws://${websocketHost}/api/client/get_client_data`
  );

  clientDataWebsocket.onopen = () => {
    console.log("Client data WebSocket connection established");
    const data = {
      client_id: clientId,
      user_id: userId
  };
  clientDataWebsocket.send(JSON.stringify(data)); 

  };

  clientDataWebsocket.onerror = (e) => {
    console.error("WebSocket error observed:", e);
    if (onErrorCallback) onErrorCallback(e);
  };

  clientDataWebsocket.onmessage = (event) => {
    const data = JSON.parse(event.data);
    if (onMessageCallback) onMessageCallback(data);
  };

  return clientDataWebsocket;
};

export const connectConversationWebSocket = (
  onMessageCallback,
  onErrorCallback
) => {

  const websocketHost = process.env.REACT_APP_WEBSOCKET_HOST || `${window.location.host}`;

  const conversationWebSocket = new WebSocket(
    `ws://${websocketHost}/ws/tab/get_conversation_tab`
  );

  conversationWebSocket.onopen = () => {
    console.log("Conversation WebSocket connection established");
    conversationWebSocket.send(userId);
  };

  conversationWebSocket.onerror = (e) => {
    console.error("WebSocket error observed:", e);
    if (onErrorCallback) onErrorCallback(e);
  };

  conversationWebSocket.onmessage = (event) => {
    const data = JSON.parse(event.data);
    console.log("Received conversation data:", data);
    if (onMessageCallback) onMessageCallback(data);
  };

  return conversationWebSocket;
};
