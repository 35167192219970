import React from "react";
import "./Sidebar.css"; // Import CSS for Sidebar styling

import leboncoinLogo from './lbc.png'; // Replace with the actual path to your leboncoin logo
import facebookLogo from './fb.jpeg'; // Replace with the actual path to your Facebook logo

function Sidebar({ conversations, selectConversation }) {
  console.log("Rendering Sidebar with conversations:", conversations);

  // Function to return the logo based on conversation type
  const getLogo = (type) => {
    switch (type) {
      case "le boncoin":
        return <img src={leboncoinLogo} alt="Le Bon Coin Logo" className="logo" />;
      case "mail":
        return <span className="email-logo">📧</span>;
      case "facebook":
        return <img src={facebookLogo} alt="Facebook Logo" className="logo" />;
        case "form":
          return <span className="email-logo">📝</span>;
      default:
        return null;
    }
  };

  // Function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();

    const isToday = date.toDateString() === today.toDateString();

    if (isToday) {
      return date.toLocaleTimeString('fr-FR', {
        hour: '2-digit',
        minute: '2-digit',
      });
    } else {
      return date.toLocaleDateString('fr-FR', {
        day: '2-digit',
        month: 'long',
      });
    }
  };

  // Function to decode MIME encoded words
  const decodeMimeWord = (encodedWord) => {
    const match = encodedWord.match(/=\?([^?]+)\?(B|Q)\?([^?]+)\?=/i);
    if (!match) return encodedWord;

    const charset = match[1].toLowerCase();
    const encoding = match[2].toUpperCase();
    const text = match[3];

    try {
        if (encoding === "B") {
            // Base64 decoding
            return decodeURIComponent(
                escape(atob(text))
            );
        } else if (encoding === "Q") {
            // Quoted-Printable decoding
            return decodeURIComponent(
                escape(
                    text.replace(/_/g, " ").replace(/=([A-Fa-f0-9]{2})/g, (match, p1) => 
                        String.fromCharCode(parseInt(p1, 16))
                    )
                )
            );
        }
    } catch (e) {
        console.error("Decoding error", e);
        return encodedWord; // Return the original string if decoding fails
    }
    return encodedWord;
};

  return (
    <div className="sidebar">
      {conversations.map((conv, index) => (
        <div
          key={index}
          className="conversation"
          onClick={() => selectConversation(conv)}
        >
          <div className="conversation-name">
            {getLogo(conv.conversation_type)}
            {decodeMimeWord(conv.client_name)}
            <span className="time-or-date">{formatDate(conv.last_time_received)}</span>
            {conv.is_ia_running && <div className="spinner"></div>}
          </div>
          <div className="conversation-preview">
            <b>{conv.is_last_message_client ? "client" : "vous"}</b> : {conv.is_read ? conv.last_conversation_message : <b>{conv.last_conversation_message}</b>}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Sidebar;
