import React, { useState } from "react";
import Modal from "./ModalPDF"; // Assuming you have this component
import ProductItem from "./ProductItem";
import StockItem from "./StockItem";

const DevisModal = ({ products, stockItems, isOpen, onClose, handleQuantityChange, handleDeleteProduct, handleAddProduct, saveProducts }) => {
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const filteredStockItems = selectedCategory
    ? stockItems.filter(item => item.category === selectedCategory)
    : stockItems;

  return (
    <Modal isOpen={isOpen} onClose={onClose} contentLabel="Modifier le Devis">
      <h2>Modifier le Devis</h2>
      <h3>Produits du devis</h3>
      <ul>
        {products.map((product, index) => (
          <ProductItem
            key={index}
            product={product}
            index={index}
            onQuantityChange={(delta) => handleQuantityChange(index, delta)}
            onDelete={() => handleDeleteProduct(index)}
          />
        ))}
      </ul>
      <h3>Stock du dépots</h3>
      <div className="filter-container">
        <label htmlFor="category-select">Filter par catégorie : </label>
        <select id="category-select" value={selectedCategory} onChange={handleCategoryChange}>
          <option value="">Tous</option>
          {[...new Set(stockItems.map(item => item.category))].map(category => (
            <option key={category} value={category}>{category}</option>
          ))}
        </select>
      </div>
      <ul>
        {filteredStockItems.map((item) => (
          <div key={item.ref} className="stock-item-container">
            <StockItem item={item} />
            <button onClick={() => handleAddProduct(item)}>Ajouter un paquet</button>
          </div>
        ))}
      </ul>
    </Modal>
  );
};

export default DevisModal;
