import React, { useState, useEffect } from "react";
import Modal from "./ModalPDF";
import DevisModal from "./DevisModal";
import DevisButtons from "./DevisButtons";
import "./ReportDisplay.css";

const ReportDisplay = ({
  clientData,
  clientId,
  setProducts,
  addProduct,
  changeProductQuantity,
  deleteProduct,
}) => {

  const userId = localStorage.getItem('userId');

  const [proposedAnswer, setProposedAnswer] = useState("");

  useEffect(() => {
    setProposedAnswer(clientData.ia_proposed_answer);
  }, [clientData.ia_proposed_answer]);

  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [showSendConfirmPopup, setShowSendConfirmPopup] = useState(false);
  const [stockItems, setStockItems] = useState([]);

  const fetchStockItems = async () => {
    try {
      const http_host =
        process.env.REACT_APP_HTTP_HOST || `${window.location.host}`;
      const response = await fetch(`http://${http_host}/api/stock/get_stock`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ user_id: userId })
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setStockItems(data.stock_items || []);
    } catch (error) {
      console.error("Error fetching stock items:", error);
    }
  };

  const handleSendClick = () => {
    setShowConfirmPopup(true);
  };

  const handleGetNewQuote = async () => {
    const http_host =
      process.env.REACT_APP_HTTP_HOST || `${window.location.host}`;
    const response = await fetch(`http://${http_host}/api/client/new_answer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ client_id: clientId, user_id: userId }),
    });
    if (response.ok) {
      alert("Message généré avec succès!");
    } else {
      alert("Échec de la création du nouveau devis.");
    }
  };

  const handleConfirmSend = async () => {
    setShowConfirmPopup(false);
    const http_host =
      process.env.REACT_APP_HTTP_HOST || `${window.location.host}`;
    const response = await fetch(
      `http://${http_host}/api/client/post_send_message_via_email`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message: proposedAnswer,
          client_id: clientId,
          user_id: userId
        }),
      }
    );
    if (response.ok) {
      alert("Message envoyé avec succès!");
    } else {
      alert("Échec de l'envoi du message.");
    }
  };

  const handleCancelSend = () => {
    setShowConfirmPopup(false);
  };

  const fetchPdfUrl = async () => {
    const http_host =
      process.env.REACT_APP_HTTP_HOST || `${window.location.host}`;
    const response = await fetch(
      `http://${http_host}/api/quote/get_client_pdf`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ client_id: clientId, user_id: userId })
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch PDF URL");
    }
    const data = await response.json();
    return data.pdf_url;
  };

  const handleShowDevis = async () => {
    try {
      const url = await fetchPdfUrl();
      const http_host =
        process.env.REACT_APP_HTTP_HOST || `${window.location.host}`;
      setPdfUrl(`http://${http_host}${url}`);
      setShowModal(true);
    } catch (error) {
      console.error("Error fetching PDF URL:", error);
      alert("Échec de la récupération du devis.");
    }
  };

  const handleDownloadDevis = async () => {
    try {
      const url = await fetchPdfUrl();
      const link = document.createElement("a");
      const http_host =
        process.env.REACT_APP_HTTP_HOST || `${window.location.host}`;
      link.href = `http://${http_host}${url}`;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error fetching PDF URL:", error);
      alert("Échec de la récupération du devis.");
    }
  };

  const handleSendDevis = () => {
    setShowSendConfirmPopup(true);
  };

  const confirmSendDevis = () => {
    const http_host =
      process.env.REACT_APP_HTTP_HOST || `${window.location.host}`;
    fetch(`http://${http_host}/api/quote/post_send_quote_via_email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ client_id: clientId, user_id: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        alert("Devis envoyé avec succès!");
        setShowSendConfirmPopup(false);
      })
      .catch((error) => {
        console.error("Error sending devis:", error);
        alert("Échec de l'envoi du devis.");
        setShowSendConfirmPopup(false);
      });
  };

  const cancelSendDevis = () => {
    setShowSendConfirmPopup(false);
  };

  const handleEditDevis = () => {
    fetchStockItems();
    setShowEditModal(true);
  };

  const handleDeleteProduct = (index) => {
    deleteProduct(
      clientId,
      clientData.report.report_products.report_products[index].product
    );
    const newProducts =
      clientData.report.report_products.report_products.filter(
        (_, i) => i !== index
      );
    setProducts(newProducts);
  };

  const handleQuantityChange = (index, newQuantity) => {
    const newProducts = clientData.report.report_products.report_products.map(
      (product, i) => {
        if (i === index) {
          return {
            ...product,
            product: {
              ...product.product,
              nb_pcs: {
                ...product.product.nb_pcs,
                nb_pcs: newQuantity,
              },
            },
          };
        }
        return product;
      }
    );
    setProducts(newProducts);
    const updatedProduct = newProducts[index].product;
    changeProductQuantity(clientId, updatedProduct);
  };

  const handleAddProduct = (item) => {
    const newProduct = {
      name: item.label,
      raw_description: item.description,
      nb_pcs: {
        nb_pcs: 1,
      },
    };

    setProducts([
      ...clientData.report.report_products.report_products,
      { product: newProduct },
    ]);

    addProduct(clientId, newProduct);
  };

  const handleProposedAnswerChange = (e) => {
    setProposedAnswer(e.target.value);
  };

  return (
    <div className="report-display">
      <h2>Analyses : </h2>

      <h3>Réponse proposée par l'IA :</h3>
      <textarea
        value={proposedAnswer}
        onChange={handleProposedAnswerChange}
        rows="15"
        cols="65"
      />
      <br />
      <button onClick={handleSendClick}>Envoyer la réponse au client</button>

      <button onClick={handleGetNewQuote}>
        Générer une nouvelle réponse
      </button>

      {showConfirmPopup && (
        <div className="confirm-popup">
          <p>Êtes-vous sûr de vouloir envoyer ce message ?</p>
          <button onClick={handleConfirmSend}>Oui</button>
          <button onClick={handleCancelSend}>Non</button>
        </div>
      )}

      <DevisButtons
        handleShowDevis={handleShowDevis}
        handleDownloadDevis={handleDownloadDevis}
        handleSendDevis={handleSendDevis}
        handleEditDevis={handleEditDevis}
      />

      {showModal && (
        <Modal onClose={() => setShowModal(false)}>
          <iframe
            src={pdfUrl}
            title="Devis PDF"
            width="100%"
            height="500px"
          ></iframe>
        </Modal>
      )}

      {showSendConfirmPopup && (
        <div className="confirm-popup">
          <p>Êtes-vous sûr de vouloir envoyer ce devis ?</p>
          <button onClick={confirmSendDevis}>Oui</button>
          <button onClick={cancelSendDevis}>Non</button>
        </div>
      )}

      {showEditModal && (
        <DevisModal
          products={clientData.report.report_products.report_products}
          stockItems={stockItems}
          isOpen={showEditModal}
          onClose={() => setShowEditModal(false)}
          handleQuantityChange={handleQuantityChange}
          handleDeleteProduct={handleDeleteProduct}
          handleAddProduct={handleAddProduct}
          saveProducts={() => confirmSendDevis()}
        />
      )}

      {/* {clientData.report &&
        clientData.report.report_products &&
        clientData.report.report_products.report_products && (
          <ProductList
            products={clientData.report.report_products.report_products}
            handleDeleteProduct={handleDeleteProduct}
            handleQuantityChange={handleQuantityChange}
          />
        )} */}
    </div>
  );
};

export default ReportDisplay;
