import React, { useState, useEffect } from "react";
import "./StoreConfigModal.css";

function StoreConfigModal({ closeModal }) {
  const userId = localStorage.getItem('userId'); // Get the userId from localStorage

  const [stores, setStores] = useState([]);

  const [newStore, setNewStore] = useState({
    id: "init_store",
    name: "",
    user_id: userId,
    imap: {
      imap_server: "belette.o2switch.net",
      imap_port: 993,
      imap_user: "",
      imap_password: "",
      imap_receiver_box: "INBOX",
      imap_sent_box: "INBOX.Sent",
      smtp_email: "",
      facebook_page_id: "",
      facebook_page_token: "",
    },
  });

  

  useEffect(() => {
    const fetchStores = async () => {
      const http_host =
        process.env.REACT_APP_HTTP_HOST || `${window.location.host}`;
      const response = await fetch(`http://${http_host}/api/stores/get_stores`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user_id: userId })
      });
      const data = await response.json();
      setStores(data);
    };

    fetchStores();
  }, []);

  const initStores = async () => {
    const http_host =
      process.env.REACT_APP_HTTP_HOST || `${window.location.host}`;
    await fetch(`http://${http_host}/api/stores/init_stores`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ user_id: userId })
    });
  };

  const addStore = async () => {
    const http_host =
      process.env.REACT_APP_HTTP_HOST || `${window.location.host}`;
    const response = await fetch(`http://${http_host}/api/stores/add_store`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newStore),
    });
    const data = await response.json();
    setStores([...stores, data]);
    setNewStore({ id: "init_id", name: "", user_id: userId, imap: {} });
  };

  const removeStore = async (id) => {
    const http_host =
      process.env.REACT_APP_HTTP_HOST || `${window.location.host}`;
    await fetch(`http://${http_host}/api/stores/delete_store`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id:id, user_id: userId}),
    });

    setStores(stores.filter((store) => store.id !== id));
  };

  const updateStore = (id, updatedStore) => {
    const updatedStores = stores.map((store) =>
      store.id === id ? updatedStore : store
    );
    setStores(updatedStores);
  };

  const saveChanges = async (id) => {
    const store = stores.find((store) => store.id === id);
    const http_host =
      process.env.REACT_APP_HTTP_HOST || `${window.location.host}`;
    await fetch(`http://${http_host}/api/stores/update_store`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(store),
    });
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Configurer les dépôts</h2>
        <div className="store-form">
          <input
            type="text"
            placeholder="Nom du dépôt"
            value={newStore.name}
            onChange={(e) => setNewStore({ ...newStore, name: e.target.value })}
          />
          <input
            type="text"
            placeholder="IMAP Serveur"
            value={newStore.imap.imap_server || ""}
            onChange={(e) =>
              setNewStore({
                ...newStore,
                imap: { ...newStore.imap, imap_server: e.target.value },
              })
            }
          />
          <input
            type="number"
            placeholder="IMAP Port"
            value={newStore.imap.imap_port || ""}
            onChange={(e) =>
              setNewStore({
                ...newStore,
                imap: { ...newStore.imap, imap_port: parseInt(e.target.value) },
              })
            }
          />
          <input
            type="text"
            placeholder="IMAP Utilisateur"
            value={newStore.imap.imap_user || ""}
            onChange={(e) =>
              setNewStore({
                ...newStore,
                imap: { ...newStore.imap, imap_user: e.target.value },
              })
            }
          />
          <input
            type="text"
            placeholder="IMAP Mot de passe"
            value={newStore.imap.imap_password || ""}
            onChange={(e) =>
              setNewStore({
                ...newStore,
                imap: { ...newStore.imap, imap_password: e.target.value },
              })
            }
          />
          <input
            type="text"
            placeholder="Boîte de réception"
            value={newStore.imap.imap_receiver_box || ""}
            onChange={(e) =>
              setNewStore({
                ...newStore,
                imap: { ...newStore.imap, imap_receiver_box: e.target.value },
              })
            }
          />
          <input
            type="text"
            placeholder="Boîte d'envoi"
            value={newStore.imap.imap_sent_box || ""}
            onChange={(e) =>
              setNewStore({
                ...newStore,
                imap: { ...newStore.imap, imap_sent_box: e.target.value },
              })
            }
          />
          <input
            type="text"
            placeholder="SMTP email"
            value={newStore.imap.smtp_email || ""}
            onChange={(e) =>
              setNewStore({
                ...newStore,
                imap: { ...newStore.imap, smtp_email: e.target.value },
              })
            }
          />
          <input
            type="text"
            placeholder="Meta page ID"
            value={newStore.facebook_page_id || ""}
            onChange={(e) =>
              setNewStore({ ...newStore, facebook_page_id: e.target.value })
            }
          />
          <input
            type="text"
            placeholder="Meta page TOKEN"
            value={newStore.facebook_page_token || ""}
            onChange={(e) =>
              setNewStore({ ...newStore, facebook_page_token: e.target.value })
            }
          />
          <button onClick={addStore}>Ajouter dépôt</button>
        </div>
        <div className="store-list">
          {stores.map((store) => (
            <div key={store.id} className="store-item">
              <input
                type="text"
                value={store.name}
                onChange={(e) =>
                  updateStore(store.id, { ...store, name: e.target.value })
                }
              />
              <input
                type="text"
                value={store.imap.imap_server || ""}
                onChange={(e) =>
                  updateStore(store.id, {
                    ...store,
                    imap: { ...store.imap, imap_server: e.target.value },
                  })
                }
              />
              <input
                type="number"
                value={store.imap.imap_port || ""}
                onChange={(e) =>
                  updateStore(store.id, {
                    ...store,
                    imap: {
                      ...store.imap,
                      imap_port: parseInt(e.target.value),
                    },
                  })
                }
              />
              <input
                type="text"
                value={store.imap.imap_user || ""}
                onChange={(e) =>
                  updateStore(store.id, {
                    ...store,
                    imap: { ...store.imap, imap_user: e.target.value },
                  })
                }
              />
              <input
                type="text"
                value={store.imap.imap_password || ""}
                onChange={(e) =>
                  updateStore(store.id, {
                    ...store,
                    imap: { ...store.imap, imap_password: e.target.value },
                  })
                }
              />
              <input
                type="text"
                value={store.imap.imap_receiver_box || ""}
                onChange={(e) =>
                  updateStore(store.id, {
                    ...store,
                    imap: { ...store.imap, imap_receiver_box: e.target.value },
                  })
                }
              />
              <input
                type="text"
                value={store.imap.imap_sent_box || ""}
                onChange={(e) =>
                  updateStore(store.id, {
                    ...store,
                    imap: { ...store.imap, imap_sent_box: e.target.value },
                  })
                }
              />
              <input
                type="text"
                value={store.imap.smtp_email || ""}
                onChange={(e) =>
                  updateStore(store.id, {
                    ...store,
                    imap: { ...store.imap, smtp_email: e.target.value },
                  })
                }
              />
              <input
                type="text"
                value={store.facebook_page_id || ""}
                onChange={(e) =>
                  updateStore(store.id, {
                    ...store,
                    facebook_page_id: e.target.value,
                  })
                }
              />
              <input
                type="text"
                value={store.facebook_page_token || ""}
                onChange={(e) =>
                  updateStore(store.id, {
                    ...store,
                    facebook_page_token: e.target.value,
                  })
                }
              />
              <button onClick={() => saveChanges(store.id)}>Sauvegarder</button>
              <button onClick={() => removeStore(store.id)}>Supprimer</button>            
            </div>
          ))}
        </div>
        <button onClick={() => initStores()}>Reset</button>
        <button onClick={closeModal}>Fermer</button>
      </div>
    </div>
  );
}

export default StoreConfigModal;
