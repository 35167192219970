import { useEffect, useRef } from "react";
import {
  connectClientDataWebSocket,
  connectConversationWebSocket,
} from "./websockets";

const useWebSockets = ({
  setConversations,
  setSelectedConversation,
  setClientData,
}) => {
  const clientDataWebsocket = useRef(null);
  const conversationWebSocket = useRef(null);
  const settersRef = useRef({
    setConversations,
    setSelectedConversation,
    setClientData,
  });

  useEffect(() => {
    conversationWebSocket.current = connectConversationWebSocket(
      (data) => {
        if (data.conversations) {
          settersRef.current.setConversations(data.conversations);
        }
      },
      (error) => {
        console.error("Error in conversation WebSocket:", error);
      }
    );

    clientDataWebsocket.current = connectClientDataWebSocket(
      "1",
      (data) => {
        if (data.conversation) {
          settersRef.current.setMessages(data.conversation.messages || []);
        }

        if (data.client) {
          settersRef.current.setClientData(data.client);
        }
      },
      (error) => {
        console.error("Error in client data WebSocket:", error);
      }
    );

    return () => {
      if (clientDataWebsocket.current) clientDataWebsocket.current.close();
      if (conversationWebSocket.current) conversationWebSocket.current.close();
    };
  }, []);

  const updateClientDataWebSocket = (clientId) => {
    if (clientDataWebsocket.current) clientDataWebsocket.current.close();
    clientDataWebsocket.current = connectClientDataWebSocket(
      clientId,
      (data) => {

        console.log("Received client data on websocket:", data);

        if (data) {
          settersRef.current.setClientData(data);
        }
      },
      (error) => {
        console.error("Error in client data WebSocket:", error);
      }
    );
  };

  const updateProducts = async (clientId, newProducts) => {
    try {
      const http_host = process.env.REACT_APP_HTTP_HOST || `${window.location.host}`;

      const response = await fetch(
        `http://${http_host}/api/client/modify_products`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            client_id: clientId,
            new_product: newProducts,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update products");
      }
    } catch (error) {
      console.error("Error updating products:", error);
    }
  };

  const addProduct = async (clientId, product) => {
    try {
      const http_host = process.env.REACT_APP_HTTP_HOST || `${window.location.host}`;

      const response = await fetch(
        `http://${http_host}/api/client/add_product`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            client_id: clientId,
            product: {
              name: product.name,
              nb_pcs: product.nb_pcs.nb_pcs,
            },
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to add product");
      }
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  const changeProductQuantity = async (clientId, product) => {
    try {
      const http_host = process.env.REACT_APP_HTTP_HOST || `${window.location.host}`;

      const response = await fetch(
        `http://${http_host}/api/client/modify_products_quantity`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            client_id: clientId,
            product: {
              name: product.name,
              nb_pcs: product.nb_pcs.nb_pcs,
            },
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to modify product quantity");
      }
    } catch (error) {
      console.error("Error modifying product quantity:", error);
    }
  };

  const deleteProduct = async (clientId, product) => {
    try {
      const http_host = process.env.REACT_APP_HTTP_HOST || `${window.location.host}`;

      const response = await fetch(
        `http://${http_host}/api/client/delete_product`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            client_id: clientId,
            product_name: product.name,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to modify product quantity");
      }
    } catch (error) {
      console.error("Error modifying product quantity:", error);
    }
  };

  return {
    updateClientDataWebSocket,
    updateProducts,
    addProduct,
    changeProductQuantity,
    deleteProduct,
  };
};

export default useWebSockets;
