import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "./TopBar.css";

Modal.setAppElement("#root");

const TopBar = () => {
  const [posName, setPosName] = useState("");
  const [stockItems, setStockItems] = useState([]);
  const [isStockModalOpen, setIsStockModalOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isDepotHoursModalOpen, setIsDepotHoursModalOpen] = useState(false);
  const [depotHours, setDepotHours] = useState({
    Monday: { is_open: false, open: "", close: "" },
    Tuesday: { is_open: false, open: "", close: "" },
    Wednesday: { is_open: false, open: "", close: "" },
    Thursday: { is_open: false, open: "", close: "" },
    Friday: { is_open: false, open: "", close: "" },
    Saturday: { is_open: false, open: "", close: "" },
    Sunday: { is_open: false, open: "", close: "" },
  });

  useEffect(() => {
    fetchPosName();
    fetchStockItems();
    fetchDepotHours();
  }, []);

  const userId = localStorage.getItem('userId');

  const fetchPosName = async () => {
    try {
      const http_host = process.env.REACT_APP_HTTP_HOST || `${window.location.host}`;
      const response = await fetch(`http://${http_host}/api/stores/get_current_store`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user_id: userId })
    });
      const data = await response.json();
      setPosName(data.name);
    } catch (error) {
      console.error("Error fetching POS name:", error);
    }
  };

  const fetchStockItems = async () => {
    try {
      const http_host = process.env.REACT_APP_HTTP_HOST || `${window.location.host}`;
      const response = await fetch(`http://${http_host}/api/stock/get_stock`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user_id: userId })
    });
      const data = await response.json();
      setStockItems(data.stock_items || []);
      setCategories([...new Set(data.stock_items.map((item) => item.category))]);
    } catch (error) {
      console.error("Error fetching stock items:", error);
    }
  };

  const fetchDepotHours = async () => {
    try {
      const http_host = process.env.REACT_APP_HTTP_HOST || `${window.location.host}`;
      const response = await fetch(`http://${http_host}/api/timetable/get_timetable`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user_id: userId })
    });
      const data = await response.json();
      setDepotHours(data);
    } catch (error) {
      console.error("Error fetching depot hours:", error);
    }
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleDepotHoursChange = (day, field, value) => {
    setDepotHours((prevHours) => ({
      ...prevHours,
      [day]: {
        ...prevHours[day],
        [field]: value,
      },
    }));
  };

  const handleIsOpenChange = (day, value) => {
    setDepotHours((prevHours) => ({
      ...prevHours,
      [day]: {
        ...prevHours[day],
        is_open: value,
      },
    }));
  };

  const saveDepotHours = async () => {
    try {
      const http_host = process.env.REACT_APP_HTTP_HOST || `${window.location.host}`;
      await fetch(`http://${http_host}/api/timetable/set_timetable`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({timetable: depotHours, user_id: userId}),
      });
      alert("Les horaires ont été sauvegardés avec succès!");
      setIsDepotHoursModalOpen(false);
    } catch (error) {
      console.error("Error saving depot hours:", error);
      alert("Une erreur s'est produite lors de la sauvegarde des horaires.");
    }
  };

  const filteredStockItems = selectedCategory
    ? stockItems.filter((item) => item.category === selectedCategory)
    : stockItems;

  return (
    <div className="topbar">
      <div className="pos-name">{posName}</div>
      <button className="depot-hours-button" onClick={() => setIsDepotHoursModalOpen(true)}>
        Horaire dépôt
      </button>
      <button className="stock-button" onClick={() => setIsStockModalOpen(true)}>
        Voir le stock
      </button>

      <Modal
        isOpen={isStockModalOpen}
        onRequestClose={() => setIsStockModalOpen(false)}
        contentLabel="Stock Items"
      >
        <h2>Stock</h2>
        <button onClick={() => setIsStockModalOpen(false)}>fermer</button>
        <div className="filter-container">
          <label htmlFor="category-select">filter par categories : </label>
          <select
            id="category-select"
            value={selectedCategory}
            onChange={handleCategoryChange}
          >
            <option value="">All</option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
        <ul>
          {filteredStockItems.map((item) => (
            <li key={item.ref} className="stock-item">
              <div className="item-header">
                <p>
                  <strong>Categorie :</strong> {item.category}
                </p>
                <p>
                  <strong>Label :</strong> {item.label}
                </p>
                <p>
                  <strong>Description :</strong> {item.description}
                </p>
              </div>
              <p>
                <strong>Dimensions :</strong> longueur:{" "}
                {item.item_dimensions.length}mm, largeur:{" "}
                {item.item_dimensions.width} mm, épaisseur:{" "}
                {item.item_dimensions.thick} mm, Surface:{" "}
                {item.item_dimensions.surface} m²
              </p>
              <p>
                <strong>Prix :</strong> Par pièces :{" "}
                {item.item_prices.price_pcs}€, Par m²:{" "}
                {item.item_prices.price_m2}€, Par package:{" "}
                {item.item_prices.price_pack}€
              </p>
              <p>
                <strong>Stock :</strong> Nbrs stock: {item.item_stock_quantity.nb_pcs} pcs, Surface:
                {item.item_stock_quantity.surface_stock} m², Length: {item.item_stock_quantity.length_stock} m
              </p>
            </li>
          ))}
        </ul>
      </Modal>

      <Modal
        isOpen={isDepotHoursModalOpen}
        onRequestClose={() => setIsDepotHoursModalOpen(false)}
        contentLabel="Depot Hours"
      >
        <h2>Horaire du dépôt</h2>
        <button onClick={() => setIsDepotHoursModalOpen(false)}>fermer</button>
        <div className="depot-hours-container">
          {Object.keys(depotHours).map((day) => (
            <div key={day} className={`depot-hours-day ${!depotHours[day].is_open ? 'closed' : ''}`}>
              <h3>{day}</h3>
              <label>
                Ouverture:
                <input
                  type="time"
                  value={depotHours[day].open}
                  onChange={(e) => handleDepotHoursChange(day, "open", e.target.value)}
                  disabled={!depotHours[day].is_open}
                />
              </label>
              <label>
                Fermeture:
                <input
                  type="time"
                  value={depotHours[day].close}
                  onChange={(e) => handleDepotHoursChange(day, "close", e.target.value)}
                  disabled={!depotHours[day].is_open}
                />
              </label>
              <label>
                Ouvert:
                <select
                  value={depotHours[day].is_open ? "open" : "closed"}
                  onChange={(e) => handleIsOpenChange(day, e.target.value === "open")}
                >
                  <option value="open">Oui</option>
                  <option value="closed">Non</option>
                </select>
              </label>
            </div>
          ))}
        </div>
        <button className="save-button" onClick={saveDepotHours}>
          Sauvegarder
        </button>
      </Modal>
    </div>
  );
};

export default TopBar;
